@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/montserrat.regular.ttf') format('truetype');
	/* Add additional src lines if you have multiple font file formats */
}
body{
  font-family: 'Montserrat'!important;
  font-weight: 200;
}